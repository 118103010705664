import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationFR from "./locales/fr.json";
import translationFR_BE from "./locales/fr_be.json";
import translationNL_BE from "./locales/nl_be.json";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      fr: {
        translation: translationFR,
      },
      be_FR: {
        translation: translationFR_BE,
      },
      be_NL: {
        translation: translationNL_BE,
      },
    },
    debug: true,
    fallbackLng: "fr",
    supportedLngs: ["fr", "be_FR", "be_NL"],
  });

export default i18n;
