import React from "react";
import theme from "./app/theme";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { RedirectionHandler } from "./RedirectionHandler";
import { Route, Routes } from "react-router-dom";
import { ForgotPassword } from "./features/session/ForgotPassword";
import { UpdatePassword } from "./features/session/UpdatePassword";
import { Logout } from "./features/session/Logout";
import { Login } from "./features/session/Login";
import { MigratePasswordNeeded } from "./features/session/MigratePasswordNeeded";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        <Route
          path="/"
          element={
            <RedirectionHandler>
              <Login />
            </RedirectionHandler>
          }
        />
        <Route path="/logout" element={<Logout />} />
        <Route path="mot-de-passe-oublie" element={<ForgotPassword />} />
        <Route
          path="modifier-le-mot-de-passe"
          element={<UpdatePassword type="RESET_PASSWORD" />}
        />
        <Route path="init" element={<UpdatePassword type="INITIALIZE" />} />
        <Route path="reinit-1" element={<MigratePasswordNeeded />} />
        <Route path="reinit-2" element={<UpdatePassword type="MIGRATE" />} />
        <Route path="change-password" element={<UpdatePassword type="CHANGE_PASSWORD" />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
