import * as React from 'react';
import {Fragment, useEffect, useState} from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Avatar, Box, FormControl, Input, InputLabel, Link, Paper} from "@mui/material";
import {Link as RouterLink, useNavigate} from "react-router-dom";
import {LockOutlined, Visibility, VisibilityOff} from "@mui/icons-material";
import {avatarStyle, boxStyle, formStyle, paperSignStyle, submitStyle, typoStyle} from "./style"
import {loginAsync, selectLoginError, selectStatus, usernameFilled} from "./sessionSlice";
import {useAppDispatch} from "../../app/hooks";
import {useSelector} from "react-redux";
import {useSnackbar} from "notistack";


export const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [viewPassword, setViewPassword] = useState(false);
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const loginStatus = useSelector(selectStatus);
    const loginError = useSelector(selectLoginError);
    const dispatch = useAppDispatch();
    const updateUsername = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        dispatch(usernameFilled(event.target.value))
        setUsername(event.target.value);
    };
    const updatePassword = (event: { target: { value: React.SetStateAction<string>; }; }) => {
        setPassword(event.target.value);
    };
    const handleSubmit = (event: { preventDefault: () => void; }) => {
        event.preventDefault()
        dispatch(loginAsync({username, password}))
    }
    const togglePasswordVisibility = () => {
        setViewPassword(!viewPassword)
    }
    const visibilityIconStyles = {
        position: 'absolute',
        top: '60%',
        right: '10px',
        transform: 'translateY(-60%)',
        cursor: 'pointer'
    }
    useEffect(()=> {
        if(loginStatus === 'login-rejected'){
            if (loginError === 'MIGRATION_UPDATE_ERROR') {
                navigate('/reinit-1');
                return;
            }
            if (loginError === 'UPDATE_PASSWORD_REQUIRED') {
                navigate('/init');
                return;
            }
            enqueueSnackbar("Nom d'utilisateur inconnu ou mot de passe incorrect", {
                autoHideDuration: 5000,
                variant: 'warning'
            })
        }
    },[enqueueSnackbar, loginStatus, loginError])
    return (
        <Box sx={boxStyle}>
            <Paper className="paperSign" sx={paperSignStyle}>
                <Avatar className="avatar" sx={avatarStyle}>
                    <LockOutlined/>
                </Avatar>
                <Fragment>
                    <Typography component="h1" variant="h5">
                        Se connecter
                    </Typography>
                    <form className="Form" onSubmit={handleSubmit}>
                        <FormControl margin="normal" required fullWidth sx={formStyle}>
                            <InputLabel htmlFor="username"
                                        className="inputLabel">
                                Nom d'utilisateur
                            </InputLabel>
                            <Input id="username"
                                   name="username"
                                   autoComplete="username"
                                   autoFocus
                                   value={username}
                                   onChange={updateUsername}/>
                        </FormControl>
                        <FormControl margin="normal" required fullWidth sx={formStyle}
                         style={{position: 'relative'}}>
                            <InputLabel htmlFor="password">
                                Mot de passe
                            </InputLabel>
                            <Input name="password"
                                type={viewPassword ? 'text' : 'password'}
                                id="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={updatePassword}/>
                            {viewPassword ? <VisibilityOff style={{
                                position: 'absolute', 
                                top: '60%', 
                                right: '10px', 
                                transform: 'translateY(-60%)',
                                cursor: 'pointer'}}
                                onClick={togglePasswordVisibility}/> :
                            <Visibility style={{
                                position: 'absolute', 
                                top: '60%', 
                                right: '10px', 
                                transform: 'translateY(-60%)',
                                cursor: 'pointer'}}
                                onClick={togglePasswordVisibility}/>}
                        </FormControl>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className="Submit"
                            sx={submitStyle}
                        >
                            Se connecter
                        </Button>
                    </form>
                    <Link component={RouterLink} to="/mot-de-passe-oublie" sx={typoStyle}>
                        Mot de passe oublié
                    </Link>
                </Fragment>
            </Paper>
        </Box>
    );
}